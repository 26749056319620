/* .apexcharts-svg rect {
  -webkit-filter: drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(-2px 0px 2px rgba(0, 0, 0, 1));
}

.apexcharts-canvas.apexcharts-theme-light {
  width: 100% !important;
} */
.apexcharts-treemap-rect {
  cursor: pointer;
}

.apexcharts-text.apexcharts-pie-label {
  filter: drop-shadow(-2px 0px 6px #fff);
}

.apexcharts-datalabels:nth-child(even) {
  transform: translate(-10px, -30px);
}

.apexcharts-datalabels:nth-child(odd) {
  transform: translate(-10px, 10px);
}

#hashtagChart .apexcharts-inner.apexcharts-graphical {
  transform: scale(1.02555) translate(0px, 20px);
}

/*.apexcharts-datalabels*/
