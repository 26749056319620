body {
  font-family: "be_vietnam_proregular", Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #64748b;
}

@media (min-width: 1605px) {
  .container {
    max-width: 1600px;
  }
}

input:focus {
  box-shadow: 0 0 0 1px #4a55fd !important;
  border-color: #4a55fd !important;
}

.login-form .input-group .form-control {
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}

.login-form .input-group-text {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background-color: #4a55fd !important;
  color: #fff !important;
}

.primary-border {
  border-color: #4a55fd !important;
  box-shadow: 0 0 0 1px #4a55fd !important;
}

.custom-checkbox:not(.custom-checked) label:before {
  border-color: #64748b;
}

.custom-checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #4a55fd;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.custom-scroll::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ccc;
}

.info-icon:hover {
  color: #4a55fd;
}
@keyframes slidebg {
  100% {
    background-position: 18vw;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes fadeBoxShadow {
  100% {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 10px;
  }
}

.flashing-button {
  /* background-image: linear-gradient(
    90deg,
    rgb(74, 85, 253) 0%,
    rgba(255, 255, 255, 0.3) 49%,
    rgb(74, 85, 253) 80%,
    rgb(74, 85, 253) 100%
  );
  animation: blinker 2s linear infinite; */
  transition: all 0.3s ease 0s;
  /*   box-shadow: 0 0 0.1vw 0.2vw #e0f7fa, 0 0 0.4vw 0.3vw #63f3dc,
    0 0 1vw 0.2vw #63f3dc;
  transform: translateY(-3px); */

  /* box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  transform: translateY(-3px); */
  animation: 1.45s cubic-bezier(0.5, 0, 0, 1) 0s infinite normal none running
    fadeBoxShadow !important;
}

.news-media-agency-logo {
  position: absolute;
  left: 0;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  z-index: 1;
  width: 30px;
  opacity: 0.8;
}

.news-media-agency-logo:nth-child(2) {
  left: 20px;
}
.news-media-agency-logo:nth-child(3) {
  left: 38px;
}
.news-media-agency-logo:nth-child(4) {
  left: 56px;
}
.news-media-agency-logo:nth-child(5) {
  left: 74px;
}
.news-media-agency-logo:nth-child(6) {
  left: 92px;
}
.news-media-agency-logo:nth-child(7) {
  left: 108px;
}
.news-media-agency-logo:nth-child(8) {
  left: 126px;
}
.news-media-agency-logo:hover {
  transform: scale(1.1);
  z-index: 12;
  opacity: 1;
}

.no-magnify {
  transform: none !important;
}

.progress .progress-bar {
  background-color: #ff9d4e !important;
}
